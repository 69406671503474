import React from 'react'

export const Footer = () => {
  return (
    <div id='footer'>
      <div className='container text-center'>
        <p>&copy; 2024 Drisseheart</p>
      </div>
    </div>
  )
}

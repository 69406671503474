import React from 'react'
import { NSFW } from './nsfw'
import { Artwork } from './artwork'

export const Services = (props) => {
  return (
    <div id='services' className='text-center blurry-background'>
      <div className='container'>
        <div className='section-title font-semibold text-black'>
          <h2>Our Services</h2>
          <div className={'text-gray-800 text-2xl'}>
            Before ordering your commission, please be sure to read the guidelines in the TOS carefully.
          </div>
        </div>
        <div>
          <img src={'img/services/png_vtube.png'} className='img-responsive' alt='' />{' '}
        </div>
        <div className={'text-2xl text-gray-800 font-semibold'}>
          These are PNG only, I do not make animated avatars nor 3D rigs.
        </div>
        <div className={'flex flex-row justify-between mt-12 gap-16 px-44'}>
          <div>
            <img src={'img/services/emotes.png'} className='img-responsive' alt='' />{' '}
          </div>

          <div>
            <img src={'img/services/badges.png'} className='img-responsive' alt='' />{' '}
          </div>

          <div>
            <img src={'img/services/panels.png'} className='img-responsive' alt='' />{' '}
          </div>
        </div>
        <div className={'mt-12 flex flex-col text-gray-800 text-2xl font-semibold gap-4'}>
          <div>Starter pack available for $150 (First time costumers only)</div>
          <div>Includes 6 badges, 6 emotes, and 1 channel point image or 1 panel</div>
          <div>Emotes included sizes: 250px, 112px, 56px, 28px</div>
          <div>Badges included sizes: 72px, 36px, 18px</div>
        </div>

        <div className={'flex flex-row justify-between gap-4'}>
          <NSFW />
          <Artwork />
        </div>
      </div>
    </div>
  )
}

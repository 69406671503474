import React from 'react'

export const Artwork = () => {
  return (
    <div className='flex justify-center items-center'>
      <div className={'flex flex-col justify-center items-start w-fit'}>
        <div className='mt-24 mb-12 text-5xl text-black font-bold'>Artwork</div>
        <div className={'flex'}>
          <img src={'img/services/artwork.jpeg'} className='img-responsive' alt='' />{' '}
          <div className={'flex flex-col text-start text-gray-800 text-3xl ms-12 font-semibold'}>
            <div>Headshot</div>
            <div className={'mt-4'}>♥ Flat - $40</div>
            <div className={'mt-4'}>♥ Shaded - $50</div>
            <div className={'mt-12'}>- Torso -</div>
            <div className={'mt-4'}>♥ Flat - $60</div>
            <div className={'mt-4'}>♥ Shaded - $70</div>
            <div className={'mt-12'}>- Full body -</div>
            <div className={'mt-4'}>Flat - $80</div>
            <div className={'mt-4'}>Shaded - $100</div>
          </div>
        </div>

        <div className={'w-full mt-12 flex flex-col gap-4 justify-center text-gray-800 text-2xl font-semibold'}>
          <div>+15 Per additional character.</div>
          <div>♥ Full color and simple effects.</div>
          <div>♥ May include minimal backgrounds.</div>
          <div>♥All packs include respective progress stages.</div>
        </div>
      </div>
    </div>
  )
}

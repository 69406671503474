import React, { useState } from 'react'
import Lightbox from 'react-18-image-lightbox'
import PropTypes from 'prop-types'

export const Gallery = (props) => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const images = props.data

  const customModalStyle = {
    overlay: {
      zIndex: 1000,
      backgroundColor: 'transparent',
    },
    content: {
      zIndex: 40, // Set the z-index for the content
    },
  }

  if (!images) return <div>Loading...</div>

  return (
    <div id='portfolio' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2 className={'font-semibold text-black'}>Portfolio</h2>
          <div className={'text-gray-800 text-xl'}>
            <p>Here are some examples of my work. If you like what you see, feel free to reach out to me.</p>
          </div>
        </div>
        <div className='row'>
          <div className='portfolio-items grid grid-cols-4 gap-4'>
            {images.map((image, index) => (
              <div key={'image_' + index} className='w-full cursor-pointer'>
                <img
                  key={image.id}
                  src={image.src}
                  alt={image.title}
                  onClick={() => {
                    setPhotoIndex(index)
                    props.setIsOpen(true)
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        {props.isOpen && (
          <div className={'-z-50'}>
            <Lightbox
              discourageDownloads={true}
              imageCaption={images[photoIndex].title}
              enableZoom={false}
              imagePadding={5}
              reactModalStyle={customModalStyle}
              mainSrc={images[photoIndex].src}
              nextSrc={images[(photoIndex + 1) % images.length].src}
              prevSrc={images[(photoIndex + images.length - 1) % images.length].src}
              onCloseRequest={() => props.setIsOpen(false)}
              onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
              onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
            />
          </div>
        )}
      </div>
    </div>
  )
}

Gallery.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      src: PropTypes.string,
    }),
  ),
}

import React from 'react'

const KOFI = 'https://ko-fi.com/drisseheart'

export const About = (props) => {
  return (
    <div id='about' className={'pt-44 my-12'}>
      <div className='container'>
        <div className='col-xs-12 col-md-6 w-1/3'>
          {' '}
          <img src='img/about/about.png' className='img-responsive' alt='' />{' '}
        </div>
        <div className='col-xs-12 col-md-6'>
          <div className='text-4xl font-semibold justify-center'>
            <div className='section-title font-semibold text-black flex justify-center items-center'>
              <h2 className={'mx-0 text-center'}>About me</h2>
            </div>
            <div className={'w-fit text-xl flex items-center gap-4 font-semibold'}>
              <div className={'text-gray-800'}>Commission Status:</div>
              <div className={'text-red-500'}>Closed</div>
            </div>
            <div className={'flex flex-col my-16 text-2xl gap-4 text-gray-800'}>
              <div>Drisseheart is a professional dreamer, variety gamer and streamer.</div>
              <div>Please enjoy exploring my website.</div>
              <div>
                If you would like to look into commissioning me, please make sure to review in full the respective info
                page of the commission you're interested in. I am also happy to answer all questions and doubts so don't
                hesitate to ask!
              </div>
              <div>Don't forget to follow me on the social platforms for updates and shenanigans.</div>
            </div>

            <div className={'pe-32 text-center items-center justify-center'}>
              <a href={KOFI} title={'Ko-fi'} target={'_blank'}>
                <img src={'img/about/kofi.png'} className={'w-1/6 mx-auto'} alt='' />
              </a>
            </div>

            <div className={'flex flex-row mt-8 items-center'}>
              <div className={'flex flex-col text-gray-800 text-2xl'}>
                <div className={'text-3xl font-semibold text-center'}>♥ Consider becoming a Kofinator ♥</div>

                <div className={'w-full flex flex-row justify-between gap-24'}>
                  <div>
                    <div className={'mt-12'}>Safety Blanket - $1</div>
                    <div>💚 Access to all SFW content</div>
                    <div>💚 One to two possible full color piece/s</div>
                    <div>💚 High-resolution files</div>
                  </div>

                  <div>
                    <div className={'mt-12'}>For Academic Purposes - $5</div>
                    <div>💙 Access to all of what's included in Tier 1</div>
                    <div>💙 Access to all the lewd content.</div>
                    <div>💙 One to two possible full color piece/s</div>
                    <div>💙 High-resolution files</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useState, useEffect } from 'react'
import { Navigation } from './components/navigation'
import { TOS } from './components/TOS'
import { About } from './components/about'
import { Services } from './components/services'
import { Gallery } from './components/gallery'
import { Contact } from './components/contact'
import JsonData from './data/data.json'
import SmoothScroll from 'smooth-scroll'
import './App.css'
import 'react-18-image-lightbox/style.css'
import backgroundImage from './img/background.jpg'
import { Footer } from './components/footer'
import { ToastContainer } from 'react-toastify'
import { Metas } from './components/metas'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

const App = () => {
  const [landingPageData, setLandingPageData] = useState({})
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])

  return (
    <div className={'flex flex-col gap-16 fixed-bg'} style={{ backgroundImage: `url(${backgroundImage})` }}>
      {!isOpen && <Navigation />}
      <Metas />
      <ToastContainer autoClose={3000} />
      <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Gallery data={landingPageData.portfolio} isOpen={isOpen} setIsOpen={setIsOpen} />
      <TOS data={landingPageData.Features} />
      <Contact data={landingPageData.Contact} />
      <Footer />
    </div>
  )
}

export default App

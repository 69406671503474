import React from 'react'

export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top blurry-background-title'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span> <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span> <span className='icon-bar'></span>{' '}
          </button>
          <a className='page-scroll' href='#page-top'>
            <div className={'flex flex-row items-center text-5xl text-black font-semibold '}>
              <div className='rounded-full  overflow-hidden'>
                <img src='img/logo.png' alt='' className='w-full' />
              </div>
              <div className={'ms-2'}>Drisse</div>
              <div className={'text-red-500'}>♥</div>
            </div>
          </a>
        </div>

        <div className='font-bold' id='bs-example-navbar-collapse-1'>
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='#about' className='page-scroll'>
                About
              </a>
            </li>
            <li>
              <a href='#services' className='page-scroll'>
                Services
              </a>
            </li>
            <li>
              <a href='#portfolio' className='page-scroll'>
                Portfolio
              </a>
            </li>
            {/*            <li>
              <a href='#testimonials' className='page-scroll'>
                Testimonials
              </a>
            </li>
            <li>
              <a href='#team' className='page-scroll'>
                Team
              </a>
            </li>*/}
            <li>
              <a href='#tos' className='page-scroll'>
                TOS
              </a>
            </li>
            <li>
              <a href='#contact' className='page-scroll'>
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

import React, { useState } from 'react'

const TWITTER = 'https://twitter.com/drisseheart'
const INSTAGRAM = 'https://www.instagram.com/drisseheart/'
const YOUTUBE = 'https://www.youtube.com/user/Drisse/featured'
const DISCORD = 'https://discord.gg/qzHkCsT'
const TWITCH = 'https://www.twitch.tv/drisseheart'

export const Social = () => {
  const [discordImage, setDiscordImage] = useState('./img/social/discord_white.png')

  // Function to handle mouse enter event
  const handleMouseEnter = () => {
    setDiscordImage('./img/social/discord_blue.png') // Change this to the path of your hover image
  }

  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    setDiscordImage('./img/social/discord_white.png')
  }

  return (
    <div className='flex flex-col items-center col-md-12'>
      <img src={'./img/social/social.png'} alt='social' />

      <div className='social flex flex-row mt-12 justify-center items-center gap-4'>
        <a href={TWITTER} title={'Twitter'} target={'_blank'}>
          <i className='fa fa-twitter'></i>
        </a>

        <a href={TWITCH} title={'Twitch'} target={'_blank'}>
          <i className='fa fa-twitch'></i>
        </a>

        <a href={DISCORD} title={'Discord'} target={'_blank'}>
          <img src={discordImage} alt='discord' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />
        </a>

        <a href={YOUTUBE} title={'Youtube'} target={'_blank'}>
          <i className='fa fa-youtube'></i>
        </a>

        <a href={INSTAGRAM} title={'Instagram'} target={'_blank'}>
          <i className='fa fa-instagram'></i>
        </a>
      </div>
    </div>
  )
}

import { useState } from 'react'
import emailjs from 'emailjs-com'
import React from 'react'
import { Social } from './social'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }

  const clearState = () => {
    console.log('clearing state', initialState)
    setState({ ...initialState })
  }

  // Create a toast promise
  const notify = (message, options = {}) =>
    toast.promise(
      new Promise((resolve) => setTimeout(resolve, 1000)), // This is a dummy promise, replace it with your actual promise
      {
        pending: 'Sending...',
        success: {
          icon: () => null,
          render({ closeToast }) {
            return (
              <div className={'flex flex-row items-center justify-start gap-4'}>
                <img src={'./img/success.png'} alt={'success'} />
                <div className={'text-gray-800 text-2xl '}> Email sent successfully!</div>
              </div>
            )
          },
        },
        error: 'Error sending email.',
        ...options,
      },
    )

  // Use the toast promise in the emailjs.sendForm function
  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(name, email, message)

    notify(emailjs.sendForm('service_763fnvm', 'template_fc4uzl9', e.target, '2Ywf7gUxJr3fdcHUr')).then(
      (result) => {
        clearState()
      },
      (error) => {
        console.log(error.text)
      },
    )
  }

  return (
    <div className={'blurry-background'}>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2 className={'text-black font-semibold'}>Get In Touch</h2>
                <div className={'text-gray-800'}>
                  Please fill out the form below to send me an email and I will get back to you as soon as possible.
                </div>
              </div>
              <form name='sentMessage' onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                        value={name}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='Email'
                        required
                        onChange={handleChange}
                        value={email}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Message'
                    required
                    onChange={handleChange}
                    value={message}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Send Message
                </button>
              </form>
            </div>
          </div>

          <div className='mt-32 col-md-3 col-md-offset-1 contact-info'>
            <Social />
          </div>
        </div>
      </div>
    </div>
  )
}

import { Helmet } from 'react-helmet-async'

export const Metas = () => {
  const pathname = window.location.origin
  return (
    <Helmet>
      <meta charSet='utf-8' />
      <meta name='description' content='Drisseheart is a professional dreamer, variety gamer and streamer.' />
      <meta property='og:title' content='Drisseheart' />
      <meta property='og:description' content='Drisseheart is a professional dreamer, variety gamer and streamer.' />
      <meta property='og:image' content={pathname + '/img/about/about.png'} />
      <meta property='og:url' content={pathname} />
    </Helmet>
  )
}

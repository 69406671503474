import React from 'react'

export const TOS = (props) => {
  return (
    <div id='tos' className='text-center blurry-background py-32'>
      <div className='flex flex-col justify-between text-center items-center '>
        <div className='font-semibold section-title'>
          <h2>TERMS OF SERVICE</h2>
        </div>
        <div className={'text-black font-bold text-3xl underline'}>
          Please read the terms below before commissioning me, thank you!{' '}
        </div>
        <div className={'text-black text-3xl font-semibold underline'}>
          By commissioning me, you agree to these Terms of Service.
        </div>

        <img src={'./img/rules.png'} />

        <div className={'px-6 flex flex-col text-2xl font-semibold mt-8 gap-6 text-gray-800'}>
          <div>
            ♥ Anyone under the age of 18 is strictly forbidden to contact me for any commission involving Not Safe For
            Work ( NSFW ) of any kind. By contacting me for any NSFW commission, you are expressly agreeing that you are
            at least 18 years old or older. As such, I reserve the right to cancel commission interactions if under
            suspicion that the client is under age when attempting to purchase NSFW art.
          </div>

          <div>♥ Commissions are in digital form only.</div>

          <div>
            ♥ I’m allowed to decline commissions, whether not having the time, not comfortable with the request, health
            or personal reasons. If harassed / insulted, a refund will be issued (if a payment was already issued) and
            the commission will be cancelled on the spot.
          </div>

          <div>
            ♥ I retain the right of all work and may use the artwork in portfolios, post as commission samples and all
            other forms of self-promotion. If you wish to gain exclusive rights to your commission for commercial use an
            extra 40% will be added to final price.
          </div>

          <div>
            ♥ There’s no refund once the final product is delivered. Should no payment be received, you relinquish
            ownership of the product and it can be auctioned to another buyer.
          </div>

          <div>
            ♥ Commissions work on a queue system working on first come first serve, when your time is up I'll contact
            you to double check if we're still working together and if so that your commission process is going to
            being. If you have a very tight deadline in mind please specify the time frame to work with, but keep in
            mind that an urgency fee may be included.
          </div>

          <div>
            ♥ Sketches and linework are provided free, at the end, with all packages but will always be delivered
            watermarked and numbered, if you wish to cancel the commission and keep those stages only, they'll cost the
            same as a final piece. You're free to still go ahead with cancel the commission but you will not receive
            clean versions of the sketches and they'll be auctioned as a 'Your Character Here' piece.
          </div>

          <div>♥ Feel free to contact about any doubts you may have.</div>

          <div>♥ Along a written description, please provide also any possible visual references.</div>

          <div>
            ♥ Keep in mind that during the sketch and coloring process we'll be having frequent back and forths, this
            is to make sure you're happy with the progress.
          </div>

          <div>
            ♥ You'll receive a final sample, heavily watermarked, this will only be removed once payment goes through.
            All sales are final and no refund will be issued upon completion.
          </div>

          <div>♥ Payments are done via Paypal only in USD currency.</div>

          <div className={'font-bold text-red-500 text-4xl'}>Dont's</div>

          <div>Won’t draw in someone else’s style. Includes realistic looking characters and backgrounds.</div>
          <div>I don’t draw mecha nor super detailed vehicles.</div>
          <div>Will not draw NSFW content of minors or portraying characters who look like minors.</div>
          <div>Will not draw vore, rape, nor abuse of any kind.</div>
        </div>
      </div>
    </div>
  )
}

import React from 'react'

export const NSFW = () => {
  return (
    <div className='flex justify-center items-center'>
      <div className={'flex flex-col justify-center items-start w-fit'}>
        <div className='mt-24 mb-12 text-5xl text-black font-bold text-start'>NSFW</div>
        <div className={'flex'}>
          <img src={'img/services/nsfw.jpeg'} className='img-responsive w-1/3' alt='' />{' '}
          <div className={'flex flex-col text-start text-gray-800 text-3xl ms-12 font-semibold'}>
            <div>Nude</div>
            <div className={'mt-4'}>♥ Flat - $60</div>
            <div className={'mt-4'}>♥ Shaded - $70</div>
            <div className={'mt-12'}>- Lingerie -</div>
            <div className={'mt-4'}>♥ Flat - $70</div>
            <div className={'mt-4'}>♥ Shaded - $80</div>
            <div className={'mt-12'}>- Explicit -</div>
            <div className={'mt-4'}>♥ Flat</div>
            <div className={'mt-4'}>Nude - $80</div>
            <div className={'mt-4'}>Lingerie - $90</div>
            <div className={'mt-8'}>♥ Shaded</div>
            <div className={'mt-4'}>Nude - $90</div>
            <div className={'mt-4'}>Lingerie - $100</div>
          </div>
        </div>

        <div className={'w-full  mt-12 flex flex-col gap-4 text-gray-800 text-2xl font-semibold'}>
          <div>Additional character +$20.</div>
          <div>♥ May include simple backgrounds.</div>
          <div>♥ Includes sketch and line work.</div>
        </div>
      </div>
    </div>
  )
}
